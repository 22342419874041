.new-campaigns{
    .new-campaigns-body{
        .shadow-widget{
            .nav{
                li{
                    a{
                        position: relative;
                        display: block;
                        padding: 10px 15px;
                    }
                }
            }
            .footer-buttons{
                margin: 0 -20px;
                padding: 15px 20px 0;
                .btn-default{
                    border: 1px solid #eaedef
                }
            }
            .form-group-text{
                padding-top: 22px;
                p{
                    font-size: 12px;
                    color: #717171;
                    font-weight: bold
                };
            }
            .step1{
                padding: 20px;
                span.required{
                    color: tomato
                }
                .row{
                    border-bottom: 1px solid #e0e4e8
                }
            }
            .step2{
                padding: 20px;
                span.required{
                    color: tomato
                }
               
                    .inputfile {
                        width: 0.1px;
                        height: 0.1px;
                        opacity: 0;
                        overflow: hidden;
                        position: absolute;
                        z-index: -1;
                    }
                    .inputfile + label {
                        display: block;
                        margin: 0;
                        font-size: 12px;
                        border: 1px solid #e0e4e8;
                        padding: 5px;
                        border-radius: 16px;
                        width: 82px;
                        position: relative;
                        color: black;
                        input{
                            position: absolute;
                            top: -4px;
                            left: 84px;
                            border: none;
                            color: black;
                            z-index: 1;
                        }
                    }
                    .errorMessage{
                        margin-top: 10px;
                        margin-bottom: 0;
                        border-radius: 5px;
                        color: #fff;
                        background: tomato;
                        border: none;
                        line-height: 26px;
                        padding: 0 10px;
                        clear: both;
                    }
            }
            .step3{
                padding: 20px;
                span.required{
                    color: tomato
                }
                span.dollarSpan{
                    position: absolute;
                    top: 29px;
                    right: 5px;
                }
                .radiButtuns{
                    margin-right: 5px;
                }
                .all{
                    margin-right: 5px;
                }
                .OS{
                    padding: 20px;
                    border: 1px solid #e0e4e8;
                    border-radius: 5px;
                }
                .Geo{
                    padding: 20px;
                    border: 1px solid #e0e4e8;
                    border-radius: 5px;
                }
                .add-time{
                    margin-top:10px;
                }
                .trash-time{
                    outline: 0;
                    padding: 1px 11px
                }
            }
        }
    }
}

@import '../../assets/style/constants.scss';
@mixin buttonStyle($color, $bg, $borderColor) {
    background: $bg;
    color : $color;
    border-color: $borderColor;
}

button {
    box-sizing: border-box;
    border: 2px solid;
    transition: all .2s ease-in;
    margin-right: 10px;
    &>a {
        color : $inputBordersColor !important;
        text-decoration: $inputBordersColor;
        &:hover {
            color: $mainColor !important;
            text-decoration: none !important;
        }
    }
    &:focus {
        outline: none;
    } 
    &.main {
        padding: 5px 20px;
        @include buttonStyle($white, $mainColor, $mainColor);
    }
    &.not-active {
        border: none;
        @include buttonStyle($default, $transparent, $mainColor);
    }
    
    &:hover {
        @include buttonStyle($mainColor, $transparent, $mainColor);
        cursor: pointer;
    }
}