.todays-boxes{
    background-color: #FFF;
  border-top: none;
    padding: 20px;
    overflow: visible;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji!important;
    font-weight: 300!important;
    font-size: 1.3rem!important;

    .titile{
        margin-bottom: 0;
        font-size: 1.7rem;
        font-weight: 300;
    }
    .number{
        font-size: 20px;
        margin-bottom: 0;
    }
}