.modalContainer{
    display: flex;
    position: absolute;
    z-index: 100;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    top: 0;
    left:0;
    @media (min-width: 768px){
        .modalContent{
            width: 600px
        }
        .deleteModalContent{
            width: 600px
        }
    }
    .modalContent{
        margin: 100px auto auto auto;
        min-width: 300px;
        background: white;
        border-radius: 5px;
        .modal-header{
            border-bottom: 1px solid #e5e5e5;
            padding: 15px;
            .modal-title{
                color: #767676;
                font-size: 15px;
                margin: 0;
                line-height: 1.42857143;
                font-family: inherit;
                font-weight: bold;
            }
        }
        .modal-body{
            padding: 15px;
            .chose-type{
                form{
                    label{
                        font-weight: 400;
                        color: #aaa;
                        margin-right: 50px;
                        margin-bottom: 15px;
                        cursor: pointer;
                        display: inline-block;
                        max-width: 100%;
                        .styled-radio{
                            display: inline-block;
                            margin: 4px 5px 15px 0;
                            padding: 0;
                            width: 20px;
                            height: 20px;
                            position: relative;
                            color: #81c04d;
                            input{
                                width: 100%;
                                height: 35px;
                                border-radius: 5px;
                                border: 1px solid #e0e4e8;
                                padding-left: 7px;
                                margin-right: 20px;
                                margin-bottom: 15px;
                                cursor: pointer;
                                opacity: .0001;
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: initial;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
        .modal-footer{
            margin-top: 0;
            padding: 10px 15px;
            text-align: right;
            border-top: 1px solid #e5e5e5;
        }
    }
}
