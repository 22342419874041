.verify-email-container {

  .verify-email-row {

    .verify-email-col {
      .logo {
        height: 70px;
      }
    }
  }
}

.verify-alert{
  text-align: center;
  padding: 20px;
  .error-icon{
      font-size: 60px;
      color: tomato;
  }
  .success-icon{
      font-size: 60px;
      color: #81c04d;
  }
  .errorH3{
      margin-top: 5px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      font-size: 21px;
      line-height: 25px;
      color: #a94442;
  }
  .sccessH3{
      margin-top: 5px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 25px;
  }
  span{
      color: #000;
      margin-top: 20px;
      font-size: 14px;
      display: inline-block;
      a{
          background-color: transparent;
          color: #337ab7;
          text-decoration: none;
      }
  }
}