.inner-section {
  font-family: 'Roboto', sans-serif;
  position: relative;
  height: calc(100vh);
  min-height: 100%;
  margin-top: 50px;
  .appsidebar {
    float: left;
    width: 240px;
    height: 100%;
    position: fixed;
    top: 50px;
  }
  /* Main nav
  -------------------------------------------------- */
  nav.main-navigation {
    font-weight: bold;
    font-size: 14px;
    height: 100%;
    background-color: #2c373f;
    width: 240px;
    float: left;
    &:before {
      content: "";
      display: block;
      width: 240px;
      z-index: 1;
      position: relative;
    }
    ul {
      list-style: none;
      padding: 0;
      width: 240px !important;
      margin-bottom: 0;
      li {
        background-color: #37444E;
        cursor: pointer;
        span {
          display: block;
          width: 100%;
          color: #92a4b2;
          padding: 15px 16px;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;
        }
        a {
          display: block;
          width: 100%;
          color: #92a4b2;
          padding: 15px 16px;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;

          &.aside-navbar-active {
            background-color: #4C5E6C;
            text-decoration: none;
            color: #fff;
          }
        }
        i {
          margin-right: 10px;
        }
        span {
          position: relative;
          &:before {
            font-family: FontAwesome;
            position: absolute;
            right: 10px;
            top: 15px;
            content: "\f105";
          }
        }
        &.open {
          ul {
            max-height: 200px;
          }
          span {
            &:before {
              content: "\f107";
            }
          }
        }
        &.active,
        &:hover {
          background-color: #4C5E6C;
          a,
          span {
            text-decoration: none;
            color: #fff;
          }
        }
        ul {
          max-height: 0;
          overflow: hidden;
          -webkit-transition: max-height 0.5s;
          -moz-transition: max-height 0.5s;
          transition: max-height 0.5s;
          li {
            background-color: #2C373F;
            a {
              color: #7c92a2 !important;
              text-transform: capitalize;
              padding: 5px 20px 5px 50px;
            }
            &.active,
            &:hover {
              background-color: #2C373F;
              a {
                text-decoration: none;
                color: #fff !important;
              }
            }
          }
        }
      }
    }
    .nav-seperator {
      background: #2c373f;
      color: #576b7b;
      display: block;
      padding: 7px 16px;
      font-size: 11px;
      -webkit-font-smoothing: antialiased;
    }
  }
  /* Main content
  -------------------------------------------------- */
  .main-content {
    background-color: #EBEEF0;
    //display: table-cell;
    //width: 100%;
    //padding-left: 320px;
    height: 100%;
    top: 50px;
    right: 0px;
    overflow-y: scroll;
    position: fixed;
    width: calc(100% - 240px);
    .inner-panel {
      padding-bottom: 50px;
      // overflow-x: hidden;
      // overflow-y: auto;
      // position: absolute;
      // top: 50px;
      // bottom: 0px;
      // width: 100%;
    }
  }
  /*section
  ---------------------------------------------------*/
  .content-header {
    padding: 20px 15px;
    h1 {
      margin: 0;
      float: left;
      line-height: 34px;
      font-weight: 300;
      font-size: 20px;
      text-transform: uppercase;
    }
    .btn {
      float: right;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  /*Shadow widget
  ---------------------------------------------------*/
  .shadow-widget {
    background-color: #FFF;
    border: 1px solid #e0e4e8;
    border-top: none;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    overflow: visible;
    .widget-header {
      h2 {
        color: #a2acb3;
        height: 100%;
        width: auto;
        float: left;
        font-size: 12px;
        font-weight: 700;
        padding: 14px 0;
        position: relative;
        margin: 0;
        line-height: 20px;
        cursor: text;
        text-transform: uppercase;
      }
      .panel-ctrls {
        width: auto;
        float: right;
        padding: 0;
        margin: 0;
        button {
          padding: 5px;
          width: 25px;
          border: none;
          background: none;
          margin-top: 10px;
          color: #a2acb3;
          &:active,
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
    }
    .widget-search {
      margin: 10px 0;
      input[type=text] {
        border: none;
        position: relative;
        text-indent: 10px;
      }
      &:before {
        content: "\f002";
        font: normal normal normal 14px/1 FontAwesome;
        position: absolute;
        //left: 0;
        top: 22px;
        font-size: 12px;
        z-index: 10;
        color: #767676;
      }
    }
    .widget-filter {
      margin: 10px 0;
      select {
        width: auto;
        padding-right: 20px;
      }
    }
    &.consult-widget {
      padding: 0;
      margin-bottom: 50px;
      .widget-body {
        padding: 10px 0;
        background: #7a869c;
        text-align: center;
        h2 {
          font-size: 18px;
          text-transform: uppercase;
          margin-top: 0;
          color: #fff;
          margin: 0px;
        }
        h6 {
          font-size: 16px;
          color: #fff;
          margin-bottom: 3px;
          margin-top: 0px;
        }
        span {
          color: #fff;
          font-size: 12px;
          color: rgba(255, 255, 255, .5);
        }
        img {
          height: 80px;
          width: 80px;
          margin-bottom: 10px;
        }
      }
      .widget-footer {
        .info-box {
          width: 100%;
          padding: 5px 15px;
          border-bottom: 1px solid #eee;
          h6 {
            font-size: 14px;
            color: #262d33;
            margin: 0 0 2px;
            display: inline-block;
            width: 60px;
            line-height: 30px;
          }
          strong {
            font-weight: 400;
            font-size: 12px;
            display: inline-block;
            line-height: 30px;
          }
        }
        .search-box {
          position: relative;
          input[type=text] {
            border: none;
            position: relative;
            text-indent: 10px;
            width: 100%;
            padding-left: 19px;
          }
          &:before {
            content: "\f002";
            font: normal normal normal 14px/1 FontAwesome;
            position: absolute;
            left: 10px;
            top: 12px;
            font-size: 12px;
            z-index: 10;
            color: #767676;
          }
        }
        .btn.btn-ticket {
          width: 100%;
          -webkit-border-top-left-radius: 0px;
          -webkit-border-top-right-radius: 0px;
          -moz-border-radius-topleft: 0px;
          -moz-border-radius-topright: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
    &.directLink {
      text-align: center;
      height: 50px;
      line-height: 50px;
      a {
        display: block;
      }
    }
    &.colored-blue {
      color: #a2acb3;
      background-color: #fff;
      border-top: 2px solid #2685ee;
    }
  }
  /* Info titles Style
  ---------------------------------------------------*/
  .info-tiles {
    margin: 0 0 20px;
    display: block;
    border-radius: 3px;
    color: #fff;
    .tiles-heading {
      letter-spacing: normal;
      padding: 6px 10px;
      font-weight: 700;
      box-shadow: inset 0 -50px 0 0 rgba(255, 255, 255, .1);
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      text-transform: uppercase;
      text-align: center;
      font-size: 11px;
    }
    .tiles-body {
      font-size: 32px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      padding: 15px;
      font-weight: 300;
    }
    &.tiles-success {
      .tiles-heading,
      .tiles-body {
        background: #81c04d;
      }
    }
    &.tiles-danger {
      .tiles-heading,
      .tiles-body {
        background: #ef553a;
      }
    }
    &.tiles-twit {
      .tiles-heading,
      .tiles-body {
        background: #00aced;
      }
    }
    &.tiles-warning {
      .tiles-heading,
      .tiles-body {
        background: #f9d00d;
      }
    }
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
  /* Alert Style
  ---------------------------------------------------*/
  .alert {
    padding: 15px;
    margin-bottom: 18px;
    border: 1px solid transparent;
    border-radius: 3px;
    border-width: 0 0 0 2px;
    font-size: 12px;
    line-height: 1.5;
    display: block;
    &.alert-warning {
      color: #9c8204;
      background-color: #fdeda2;
      border-color: #f9d00d;
    }
    &.alert-danger {
      color: #b4270f;
      background-color: #facfc8;
      border-color: #ef553a;
    }
    &.alert-success {
      color: #507b2c;
      background-color: #d1e8be;
      border-color: #81c04d;
    }
    &.alert-info {
      color: #03576b;
      background-color: #c1edf8;
      border-color: #05a7cf;
    }
    &.alert-primary {
      background-color: #2685ee;
    }
  }
  /* Table Style
  ---------------------------------------------------*/
  table {
    width: 100%;
    margin-bottom: 0;
    tr {
      border-bottom: 1px solid #E0E0E0;
      height: 30px;
      line-height: 25px;
      font-size: 13px;
      color: #767676;
      &:last-child {
        border-bottom: none;
      }
      &:nth-child(even) {
        background: #fcfdfe;
      }
      &:nth-child(odd) {
        background: #ffffff;
      }
      th {
        padding-left: 13px;
        border-bottom: 2px solid #e0e0e0;
        a.sort-link {
          color: #767676;
          position: relative;
          display: block;
          &:after {
            content: "\f0dc";
            font: normal normal normal 14px/1 FontAwesome;
            // position: absolute;
            font-size: 12px;
            // right: 0;
            // top: 7px;
            z-index: 100;
            color: #767676;
            padding-left: 10px;
            display: inline-block;
          }
        }
      }
      td {
        padding-left: 13px;
        a {
          cursor: pointer;
        }
      }
      &:hover {
        td {
          background-color: #f5f5f5;
        }
      }
    }
  }
  /* Form styles
  ---------------------------------------------------*/
  input[type=text],
  input[type=password] {
    height: 35px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #e0e4e8;
    padding-left: 7px;
    margin-right: 20px;
  }
  select:focus {
    outline: none;
    border: 1px solid #72d0f4;
  }
  input:focus {
    outline: none;
    border: 1px solid #72d0f4;
  }
  select {
    height: 35px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #e0e4e8;
    padding-left: 7px;
    color: #525252;
    font-weight: 300;
    margin-right: 20px;
  }
  // select:not([multiple]) {
  //     -webkit-appearance: none;
  //     -moz-appearance: none;
  //     background-attachment: scroll;
  //     background-clip: border-box;
  //     background-color: transparent;
  //     //background-image: url("../img/arrow.png");
  //     background-origin: padding-box;
  //     background-position: right 5px top;
  //     background-position-y: center;
  //     background-repeat: no-repeat;
  //     background-size: auto auto;
  //     width: 100%;
  // }
  /* Tabs
  ---------------------------------------------------*/
  .nav-tabs {
    border-bottom: none;
    margin-left: 20px;
    li {
      a {
        font-size: 12px;
        font-weight: 800;
        color: #767676;
        margin: 0;
        border: none;
        -webkit-border-radius: 0px;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius: 0px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-radius: 0px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &.active {
        a,
        a:focus,
        a:active,
        a:hover {
          background: #2685ee;
          color: #fff;
          border: none;
        }
      }
    }
  }
  /* Global styles
  ---------------------------------------------------*/
  .form-div:nth-child(2) {
    margin-top: 5px;
  }
  .table-div {
    background-color: #FFF;
    border: 1px solid #e0e4e8;
    margin: 5px 0 -1px 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    h3 {
      font-size: 12px;
      padding-left: 20px;
      color: #767676;
    }
    &.colored-blue {
      color: #a2acb3;
      background-color: #fff;
      border-top: 2px solid #2685ee;
    }
  }
  .i-icon-td {
    margin-right: 13px;
  }
  .i-icon-td {
    i {
      font-size: 15px;
      color: #59595a;
    }
  }
  /* Pagination
  ---------------------------------------------------*/
  .tab-footer {
    border: 1px solid #e0e4e8;
    height: 45px;
    background-color: #FFF;
    -webkit-border-bottom-right-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  }
  .dataTables_info {
    float: left;
    font-size: 10px;
    font-weight: 800;
    line-height: 40px;
    padding-left: 13px;
    color: #a4a4a4;
  }
  .tab-footer-span {
    float: left;
    font-size: 10px;
    font-weight: 800;
    line-height: 40px;
    padding-left: 13px;
    color: #a4a4a4;
  }
  .pagination {
    float: right;
    margin: 8px 15px 8px 0px;
  }
  .pagination-sm li a {
    padding: 5px 10px;
    font-size: 12px;
  }
  .pagination-sm li span {
    padding: 5px 10px;
    font-size: 12px;
  }
  .dataTable {
    border-bottom: 1px solid #e0e4e8;
    .btn {
      padding: 3px 10px;
      font-size: 12px;
      line-height: 1.5;
      border-radius: 3px;
    }
  }
  .paging_simple_numbers {
    float: right;
    margin: 8px 10px;
    a {
      border: 1px solid #E5E5E5;
      background-color: #FAFAFA;
      color: #707980;
      display: block;
      float: left;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
      &:first-child {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }
      &:last-child {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }
      &.paginate_button {
        padding: 5px 10px;
        font-size: 11px;
        &.current {
          z-index: 2;
          color: #fff;
          background-color: #2685ee;
          border-color: #2685ee;
          cursor: default;
        }
      }
    }
    span {
      a.paginate_button {
        border-radius: 0;
      }
    }
  }
  .title-span {
    padding: 0 10px;
    /*width: 153px;*/
    height: 35px;
    display: inline-block;
    line-height: 35px;
    text-align: center;
    background-color: #91D7C7;
    border: 1px solid #91D7C7;
    margin-right: -4px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  /* Modal
  -------------------------------------------------- */
  .modal {
    .modal-header {
      border-bottom: 1px solid #e5e5e5;
      .modal-title {
        color: #767676;
        font-size: 15px;
      }
    }
    .modal-body {
      input {
        width: 100%;
        height: 35px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: 1px solid #e0e4e8;
        padding-left: 7px;
        margin-right: 20px;
        margin-bottom: 15px;
        &:focus {
          outline: none;
          border: 1px solid #72d0f4;
        }
      }
      select {
        width: 100%;
        height: 35px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: 1px solid #e0e4e8;
        padding-left: 7px;
        color: #525252;
        font-weight: 300;
        margin-right: 20px;
        margin-bottom: 15px;
        &:focus {
          outline: none;
          border: 1px solid #72d0f4;
        }
      }
      label {
        font-weight: 400;
        color: #aaaaaa;
      }
      textarea,
      .textarea {
        width: 100%;
        min-height: 75px;
        resize: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: 1px solid #e0e4e8;
        padding-left: 7px;
        color: #525252;
        outline: none;
      }
    }
    .modal-footer {
      margin-top: 0;
      padding: 10px 15px;
      .payment-modal-btn {
        width: 115px !important;
        margin-left: 20px;
      }
    }
    .confirm-action-modal {
      padding-top: 140px;
      .modal-content {
        padding: 15px;
        border: 1px solid #ef553a;
        border-radius: 3px;
        border-width: 0 0 0 2px;
        font-size: 12px;
        line-height: 1.5;
        display: block;
        color: #b4270f;
        background-color: #facfc8;
        .modal-header {
          display: none;
        }
        .modal-body {
          padding: 0;
          font-size: 14px;
          margin-bottom: 14px;
        }
        .modal-footer {
          text-align: left;
          padding: 0;
          border-top: none;
        }
      }
    }
    .confirm-action-modal-warning {
      padding-top: 140px;
      .modal-content {
        padding: 15px;
        border: 1px solid #f9d00d;
        border-radius: 3px;
        border-width: 0 0 0 2px;
        font-size: 12px;
        line-height: 1.5;
        display: block;
        color: #9c8204;
        background-color: #fdeda2;
        .modal-header {
          display: none;
        }
        .modal-body {
          padding: 0;
          font-size: 14px;
          margin-bottom: 14px;
        }
        .modal-footer {
          text-align: left;
          padding: 0;
          border-top: none;
        }
      }
    }
  }
  /*Error
  --------------------------------------------*/
  .errorMessage {
    border-radius: 5px !important;
    color: #fff !important;
    background: tomato !important;
    border: none;
    line-height: 26px;
    margin-bottom: 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    padding: 0px 10px;
    margin-top: 5px;
    clear: both;
  }
  .errorSummary {
    display: none !important;
  }
  /*Buttons
  --------------------------------------------*/
  .btn-midnightblue {
    color: #fff;
    background-color: #37444e;
    border-color: #37444e;
    &:hover {
      color: #fff;
      background-color: #222a30;
      border-color: #1e252a;
    }
    &:focus {
      color: #fff;
      background-color: #222a30;
      border-color: #1e252a;
    }
    &.focus {
      color: #fff;
      background-color: #222a30;
      border-color: #1e252a;
    }
    &:active {
      color: #fff;
      background-color: #222a30;
      border-color: #1e252a;
    }
    &.active {
      color: #fff;
      background-color: #222a30;
      border-color: #1e252a;
    }
  }
  /*Label style
  --------------------------------------------*/
  .badge {
    padding: 3px .45em .15em;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: .84615em;
    border-radius: 3px;
    text-transform: uppercase;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    &.badge-warning {
      background-color: #f9d00d;
    }
    &.badge-danger-info {
      color: #ef553a;
      border: 2px solid #ef553a;
    }
    &.badge-danger {
      background-color: #ef553a;
    }
    &.badge-success {
      background-color: #81c04d;
    }
    &.badge-info {
      background-color: #05a7cf;
    }
    &.badge-primary {
      background-color: #2685ee;
    }
  }
  /* DatePicker
  --------------------------------------------*/
  .datePickers {
    margin-top: 10px;
  }
}

.page .open>.dropdown-toggle.btn-success {
  color: #fff;
  background-color: #68a139;
  border-color: #578730;
}

.page .open>.dropdown-toggle.btn-midnightblue {
  color: #fff;
  background-color: #37444e;
  border-color: #37444e;
}


/*Modal margin removal
--------------------------------------------*/

body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
  margin-right: 0;
}


/*Checkboxes and radios
--------------------------------------------*/

.page .styled-input.styled-checkbox.checked:before,
.page .styled-input.styled-radio.checked:before {
  color: #81c04d;
}

@charset "utf-8";
.inner-section .button {
  width: 150px !important;
  height: 35px;
}

.inner-section .shadow-widget {
  margin-bottom: 20px;
}

.inner-section .morris-hover-row-label,
.inner-section .morris-hover-point {
  font-size: 12px;
  line-height: 1.5;
  color: #707980;
}

#impression-chart,
#click-chart {
  height: 280px;
  margin-bottom: 55px;
}

#line-chart {
  height: 300px;
  margin-bottom: 90px;
}

select:focus,
input:focus {
  outline: none;
  border: 1px solid #72d0f4;
}

form select {
  width: 222px;
  height: 35px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #e0e4e8;
  padding-left: 7px;
  color: #525252;
  font-weight: 300;
  margin-right: 20px;
}

.inner-section .small-tab h3 {
  font-size: 12px;
  padding: 0px 20px;
  color: #767676;
  float: left;
}

.inner-section .small-tab ul li {
  margin: 13px 0;
}

.inner-section .small-tab .nav-tabs {
  border: none;
}

.inner-section .small-tab .nav-tabs li a {
  border-radius: 0 0 0 0;
  margin-right: 0;
  padding: 0px 15px;
  font-weight: 400;
  height: 25px;
  line-height: 25px;
  text-align: center;
}

.inner-section .small-tab .nav-tabs li {
  border: 1px solid #e0e4e8;
}

.inner-section .small-tab .nav-tabs li:first-child {
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.inner-section .small-tab .nav-tabs li:last-child {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inner-section .small-tab .nav-tabs li.active a,
.inner-section .small-tab .nav-tabs li.active a:hover,
.inner-section .small-tab .nav-tabs li.active a:focus,
.inner-section .small-tab .nav-tabs li.active a:active {
  background-color: #e0e4e8;
  border: none;
  border: 1px solid #e0e4e8;
  font-weight: 400;
}
