.app-ticket{
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    background: white;
    .header{
        width: 100%;
        height: 200px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .content{
        padding: 10px;
        .app-status{
            float: right;
            font-size: 15px;
        }
    }
    .footer{
        padding: 0px 10px 10px 10px
    }
}
.modalContainer{
    display: flex;
    position: absolute;
    z-index: 100;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    border-radius: "5px";
    top: 0;
    left:0;
    .deleteModalContent{
        margin: 100px auto auto auto;
        min-width: 300px;
        border-left: 2px solid #ef553a;
        color: #b4270f;
        padding: 15px;
        background-color: #facfc8;
        .modal-message{
            margin-bottom: 14px;
            font-size: 14px;
        }
        .modal-footer{
            margin-top: 0;
            padding: 0;
            border-top: none;
            text-align: right;
        }
    }
    @media (min-width: 768px){
        .modalContent{
            width: 600px
        }
        .deleteModalContent{
            width: 600px
        }
    }
    .modalContent{
        margin: 100px auto auto auto;
        min-width: 300px;
        background: white;
        border-radius: 5px;
        .modal-header{
            border-bottom: 1px solid #e5e5e5;
            padding: 15px;
            .modal-title{
                color: #767676;
                font-size: 15px;
                margin: 0;
                line-height: 1.42857143;
                font-family: inherit;
                font-weight: bold;
            }
        }
        .modal-body{
            padding: 15px;
        }
        .modal-footer{
            margin-top: 0;
            padding: 10px 15px;
            text-align: right;
            border-top: 1px solid #e5e5e5;
        }
    }
}

