@import './constants.scss';
@import './loader.scss';
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto");

body {
  margin: 0;
  padding: 0;
  color : $mainColor;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto',sans-serif;
}

select,
input,
textarea {
    color : $inputBordersColor;
}

#main {
    color: $textColor;
}

.btn {
    &.btn-main {
        border: 2px solid $mainColor;
        font-size: 14px;
        padding: 3px 20px;
        background: $mainColor;
        color: $white;
        box-shadow: 0 1px 6px rgba(57,73,76,.35);
        text-transform: uppercase;
        border-radius: 2px;
        &:hover {
            filter: brightness(90%);
            color: $white;
        }
    }
}
