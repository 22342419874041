.shared-table-cell{
  a {
    i:hover {
      color: #5f84ff;
    }
  }

}  
.header {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji!important;
  font-weight: 400!important;
  // font-size: 1.5rem!important;
}