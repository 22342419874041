.tab{
    background-color: #fff;
    margin-bottom: 20px;
    .tab-title{
        display: inline-block;
        padding: 0 20px;
        line-height: 34px;
        text-align: center;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;
        color: #767676;
        font-size: 14px;
        font-weight: 700;
        font-family: Roboto,sans-serif;
    }
    .selected{
        color: #fff;
        background-color: #2685ee;
    }
}
