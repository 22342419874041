.footer-buttons{
    padding-top: 10px;
    padding-bottom: 10px;
    a{
        border: 1px solid #eaedef
    }
}
.amountPart{
    width:200px
}
.paymentPart{
    display: flex;
    .coinPayments{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 5px;
        padding: 5px;
        .coinPaymentsHeader{
            display: flex;
            justify-content: space-between;
            width:100%;
            height: 20px;
            .Bitcoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/bitcoin.png')
            }
            .BlackCoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/blackcoin.png')
            }
            .Dash{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/dash.png')
            }
            .Ether{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/ether.png')
            }
            .Namecoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/namecoin.png')
            }
            .LitecoinTestnet{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/LitecoinTestnet.png')
            }
        }
    }
    .wireTransfer{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 5px;
        padding: 5px;
        display: flex;
        .wireTransferHeader{
            margin:auto;
            background-size: contain;
            background-image: url('../../assets//imgs/wiretransfer.png');
            width: 100px;
            height: 40px;
            background-repeat: no-repeat;
        }
    }
}
.wireTransferpart{
    width: 100%;
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #eaedef;
    border-radius: 5px;
    .payButton{
        margin-top: 10px;
        width: 100%;
        padding: 7px;
        background: #0095ff;
        border-radius: 5px;
        border: none;
        color: white;
        font-weight: bold;
        font-size: 20px
    }
}