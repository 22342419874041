$mainColor : #4ddfcd;
$mainColor2 : #2c3f6d;
$mainColor3 : #4d89f2;


$black : #000;
$white: #fff;
$default: #e2e5e9;
$inputBordersColor: #e4e4e4;
$errorTextColor : #dc3545;
$transparent: transparent;
$dark: #8f8f8f;
$headerBg : #fff;
$UIContentbg : #ccc;
$footerBg: #ddd;
$borderColor: #e4e4e4;
$textColor: #666;
$grayColor: #fafafa;
$grayColor2: #f9f9f9;
$grayColor3: #656565;

$formFieldErrorFontSize : 10px;
$formLabelFontSize : 12px;

// Media variables
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

// Media variables min
$screen-xs-min: $screen-xs;
$screen-sm-min: $screen-sm;
$screen-md-min: $screen-md;
$screen-lg-min: $screen-lg;

// Media variables max
$screen-xxs-max:($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

// Radius Angle of Forms
$radiusAngle: 5px;


// $media(max-width: $screen-lg)
// $media(max-width: $screen-md)
// $media(max-width: $screen-sm)
// $media(max-width: $screen-xs)
