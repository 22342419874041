.spiner-box {
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
}

.inline {
  position: relative;
  vertical-align: middle;
  margin: 0;
  left: 0;
  top: 0!important;
  margin-top: 30px!important;
  margin-bottom: 150px!important;
  -webkit-transform: none;
  transform: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}