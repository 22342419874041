.footer-buttons{
    padding-top: 10px;
    padding-bottom: 10px;
    a{
        border: 1px solid #eaedef
    }
}
.amountPart{
    width:200px
}
.paymentPart{
    display: flex;
    .stripe{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        padding: 5px;
        .stripeHeader{
            height: 25px;
            background-image: url('../../assets/imgs/creditcards.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .paypal{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        padding: 5px;
        margin-right: 5px;
        .paypalHeader{
            height: 25px;
            margin: auto;
            width: 90px;
            background-image: url('../../assets/imgs/paypal.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .coinPayments{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 5px;
        padding: 5px;
        .coinPaymentsHeader{
            display: flex;
            justify-content: space-between;
            width:100%;
            height: 20px;
            .Bitcoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/bitcoin.png')
            }
            .BlackCoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/blackcoin.png')
            }
            .Dash{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/dash.png')
            }
            .Ether{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/ether.png')
            }
            .Namecoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/namecoin.png')
            }
            .LitecoinTestnet{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/LitecoinTestnet.png')
            }
        }
    }
}
.stripeForm{
    margin-top: 20px;
    .stripeFormContainer{
        .form-group{
            .StripeElement{
                border: 1px solid #eaedef;
                padding: 10px;
                border-radius: 5px;
            }
        }
        padding: 20px;
        width: 320px;
        border-radius: 5px;
        border: 1px solid #eaedef;
        .cvsContainer{
            display: flex;
            justify-content: space-between;
            width:100%;
        }
    }
}
.coinPaymentsContainer{
    margin-top: 20px;
    .loaderContainer{
        position: relative;
        width: 200px;
        height: 200px;
    }
    .coinPaymentPart{
        width: 320px;
        margin-top: 10px;
        padding: 20px;
        border: 1px solid #eaedef;
        border-radius: 5px;
    }
}
.SuccesPaymentcontainer{
    z-index: 1234;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    background: rgba(0,0,0,0.2);
    .paymentInfocontentSucces{
        text-align: center;
        width: 600px;
        background: white;
        padding: 30px;
        margin: auto;
        border: 1px solid #c1bfbf;
        border-radius: 5px;
        box-shadow: 0 0 6px 0px rgba(0,0,0,0.1);
        .coinqrcodediv{
            width: 150px;
            height: 150px;
            background-size: cover;
            background-repeat: no-repeat;
            margin:auto;
            display: inline-block;  
            margin-bottom: 10px;
        }
        .amountdiv{
            margin-left: 22px;
        }
        .backbuttondiv{
            margin-left: 22px;
        }
      }
}