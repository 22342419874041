.app-card {
  // box-shadow: 0px -5px 4px -1px #5177dd!important;
  
}

.app-card-link {
  display: block;
  overflow: hidden;
  min-height: 69px!important;
  max-height: 70px!important;
  padding: 1rem;
}

.app-card-col {

}