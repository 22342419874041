.login-container , .recover-container{
  width: 100%;
  height: 100vh;

  .logo {
    height: 70px;
  }

  .login-form {
    margin: 1rem 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #333!important;
    
    input {
      // height: 40px;
      width: 100%!important;
      border-radius: 20px!important;
      box-shadow: 0px 0px 5px 2px #e7e7e7!important;
      color: #333;
      // font-size: 1.5rem;
      font-size: 17px;
      transition: .3s all;
      border: none!important;
    }

    input:focus {
      border: none!important;
      transition: .5s all;
      box-shadow: 0px 5px 10px 0px #e7e7e7 !important;
    }
  }

  .submit-login {
    border-radius: 20px!important;
    box-shadow: 0px 5px 10px 0px #ff4931 !important;
  }

  .forgot-btn {
    border-radius: 20px!important;
    box-shadow: 0px 5px 10px 0px #1f88be !important;
  }

  .back-btn {
    border-radius: 20px!important;
    box-shadow: 0px 5px 10px 0px #ff4931 !important;
  }

  .register-login {
    border-radius: 20px!important;
    box-shadow: 0px 5px 15px -5px #1f88be !important;
  }

  .social-media {
    color: #8b8b8b!important;
  }

  .facebook-chip {
    background: white!important;
    color: #3b5998!important;
    border-radius: 100%!important;
    box-shadow: 0px 0px 5px 2px #e7e7e7!important;
    width: 60px;
    height: 60px;
    margin: 0;
    transition: .3s all!important;
  }

  .twitter-chip {
    background: white!important;
    color: #55acee!important;
    border-radius: 100%!important;
    box-shadow: 0px 0px 5px 2px #e7e7e7!important;
    width: 60px;
    height: 60px;
    margin: 0;
    transition: .3s all!important;
  }

  .google-chip {
    background: white!important;
    color: #dd4b39!important;
    border-radius: 100%!important;
    box-shadow: 0px 0px 5px 2px #e7e7e7!important;
    width: 60px;
    height: 60px;
    margin: 0;
    transition: .3s all!important;
  }

  .linkedin-chip {
    background: white!important;
    color: #1f88be!important;
    border-radius: 100%!important;
    box-shadow: 0px 0px 5px 2px #e7e7e7!important;
    width: 60px;
    height: 60px;
    margin: 0;
    transition: .3s all!important;
  }

  .chip {
    margin: 1rem!important;
  }

  .chip:hover {
    box-shadow: 0px 0px 7px 5px #e7e7e7!important;
  }

  .left-side-bg {
    position: relative;
    background: url('../../../assets/imgs/login-background.jpg')no-repeat center center;
    background-size: cover;
    height: 100vh;
    -webkit-filter: brightness(40%);
    filter: brightness(40%);
  }

  .left-side-logo {
    height: 300px;
  }

  .left-side-container {
    height: 200px;
    width: 400px;
    position: absolute;
    top: 25%;
    right: 15%;
  }
}

.loginBack {
  position: relative;
  background: url('../../../assets/imgs/login-background.jpg')no-repeat center center;
  background-size: cover;
  height: 100vh;
  -webkit-filter: brightness(40%);
  filter: brightness(40%);
}

@media(max-width:768px) {
  .login-container , .recover-container{
    .login-quote {
      font-size: 17px
    }

    .left-side-container {

      right: 5%;
      justify-content: center;
    }
  }
}

@media(max-width:1440px) {
  .login-container , .recover-container{
    .login-quote {
      font-size: 18px
    }

    .left-side-container {
      right: 10%;
      justify-content: center;
    }
  }
}

@media(max-width:1024px) {
  .login-container , .recover-container{
    .login-quote {
      font-size: 17px
    }

    .left-side-container {
      width: 300px;
      right: 10%;
      justify-content: center;
    }
  }
}

@media(max-width:767px) {
  .login-container , .recover-container{
    .login-quote {
      font-size: 17px
    }

    .left-side-container {
      display: none;
    }

    .left-side-bg {
      display: none;
    }

    .register-login {
      font-size: 16px!important;
      font-weight: normal;
      margin-top: 1rem!important;
    }

    .submit-login {
      font-size: 16px!important;
      font-weight: normal;
    }
    .social-media {
      span {
        text-align: center;
      }
    }
  }
}