.panel-group .card .card-header h5:after {
    font-family: FontAwesome;
    content: "\F077";
    float: right;
    font-size: 15px;
}
.panel-group .card .collapsed .card-header h5:after {
    font-family: FontAwesome;
    content: "\F078";
    float: right;
    font-size: 15px;
}
.panel-group{
    padding-top: 10px;
    padding-bottom: 10px;
    .card{
        margin-bottom: 10px;
    }
    .panel-heading{
        padding: 0;
        .card-header{
            border: none;
            padding: 0;
            .panel-title{
                margin: 0;
                text-align: left;
                padding: 6px 20px;
                background: white;
                font-size: 16px;
            }
        }
    }
    .card-body{
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        padding: 6px 20px;
    }
}