.todays-container{
    display: flex;
 
    .active {
      // box-shadow: 4px -4px 5px 1px #e4e4e4;
      z-index: 100;
      // border-top: 3px solid rgb(0, 0, 255)!important;
      // box-shadow: 1px 0px 1px 1px rgb(0, 0, 255);
      box-shadow: 00px -5px 0px -1px #5177dd;
      p:nth-child(1) {
        color: #333!important;
        font-weight: 400!important;
      }
      p:nth-child(2) {
        color: #333!important;
        font-weight: 500!important;
      }
    }
    .todays-boxes:active {
      background: #d8d8d8!important;
    }
    .todays-boxes{
        background-color: #FFF;
        border-top: none;
        padding: 15px 15px;
        overflow: visible;
        border-bottom: none;
        cursor: pointer;
        transition: .5s all;
        border: none;
        color: #333!important;
        margin: 0!important;
        
        .second-value {
          // color: black!important;
          font-size: 13px;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
        }

        .second-value:nth-child(1) {
          margin-left: .1rem;
        }

        &:hover{
          // background: #f0f0f0;
          color: rgb(81, 119, 221)!important;
        }

        .titile{
          font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji!important;
          font-weight: 300;
          font-size: 1.2rem!important;
          color: rgb(68, 68, 68);
        }

        .number{
          font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji!important;
          font-weight: 300;
          font-size: 1.7rem!important;
        }
    }
}


.chart-footer{
    margin-top: 1rem;
    border-top: 1px solid  rgb(194, 194, 194);
    padding-top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
    color: #333;

    p {
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji!important;
      font-weight: 200;
      font-size: 1.2rem !important;
    }
}
