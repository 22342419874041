.registration-container {
  position: absolute;
  top: 0;
  // left: 20%;
  padding: 0;

  .registration-form  {
    margin: 1rem 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #333!important;

    input {
      height: 40px;
      border-radius: 20px!important;
      box-shadow: 0px 0px 5px 2px #e7e7e7!important;
      color: #333;
      font-size: 18px;
      transition: .3s all;
      border: none!important;
    }

   .field {

      label {
        font-weight: normal;
        // color: white;
      }
   }

    input:focus {
      border: none!important;
      transition: .5s all;
      box-shadow: 0px 5px 10px 0px #e7e7e7 !important;
    }

    input:focus~label {
      color:#333;
    }
  }
  .row {
    height: 100vh;
  }
  .reg-btn {
    border-radius: 20px!important;
    box-shadow: 0px 5px 15px -5px #1f88be !important;
  }
  .back-btn {
    border-radius: 20px!important;
    box-shadow: 0px 5px 15px -5px red !important;
  }

  .field:nth-child(7) {
    text-align: center;
  }
  .login-back {
    position: relative;
    background: url('../../../assets/imgs/login-background.jpg')no-repeat center center;
    background-size: cover;
    height: 100vh;
    -webkit-filter: brightness(40%);
    filter: brightness(40%);
  }
}

.reg-select {
  .selection {
    border-radius: 20px!important;
    box-shadow: 0px 0px 5px 2px #e7e7e7!important;
    color: #333;
    transition: .3s all;
    border: none!important;
    border-radius: 20px;
  }
}

.marked-word {
  background: rgb(31, 136, 190);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 10px;
}