@import '../../../assets/style/constants';

.input-box {
    span {
        color: $errorTextColor;
        font-size: $formFieldErrorFontSize;
    }
    &.sm-input {
        max-width : 200px;
    }
    &.md-input {
        min-width: 200px;
        max-width: 320px;
    }
    &.lg-input {
        width: 100%;
    }
    label {
        font-size: $formLabelFontSize;
        color : $dark;
    }
    .input-wrapper {
        position: relative;
        .my-icons {
            color : $mainColor;
        }
        .my-fa-icon {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translate(50%, -50%);
            &:hover {
                cursor: pointer;
            }
        }
        .password-info-icon {
            height: 20px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
            &:hover {
                cursor: pointer;
                &:after {
                    content: 'Password should includes a minimum of 1 lower case letter, minimum of 1 upper case letter, minimum of 1 numeric character and a minimum of 1 special character: ~!@#$%^&*()-_+={}[]|\;:"<>,./?';
                    position: absolute;
                    width: 150px;
                    transform: translate(10px);
                }
            }
        }
        &.email {
            .my-fa-icon {
                position: absolute;
                z-index: 1;
                right: 0;
                top: 50%;
                transform: translate(-50%, -50%);
                &:hover {
                    cursor: pointer;
                }
            }
        }
        input[type='number'] {
            -moz-appearance:textfield;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

}
